/*eslint-disable*/
import React from "react";

const CountItem = ({ value }) => {
  let num = value;
  let digits = num.toString().split("");
  let realDigits = digits.map(Number);
  console.log(realDigits);

  return (
    <div className="flex flex-row mr-2">
      {realDigits.map((num, index) => (
        <div
          style={{
            border: "1px solid white",
            borderRadius: 6,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingInline: 10,
            paddingBlock: 5,
            marginInline: 5,
            background: 'rgba(153, 153, 255, 0.2)',
          }}
        >
          <h3 className="text-xl text-white font-bold uppercase">{num}</h3>
        </div>
      ))}
    </div>
  );
};

export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  return (
    <>
      <nav className="top-0 absolute z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-center">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            {/* <img src={Logo} alt="logo" /> */}
            {/* <div className="flex flex-row items-center">
              <CountItem value={10231} />
              <h3 className="text-xl text-white font-bold uppercase">
                offers claimed
              </h3>
            </div> */}
            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="text-white fas fa-bars"></i>
            </button>
          </div>
        </div>
      </nav>
    </>
  );
}
