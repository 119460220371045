import image1 from "assets/img/1-dundee-slots.png";
import image2 from "assets/img/2-neospin-casino.png";
import image3 from "assets/img/3-ricky-casino.png";
import image4 from "assets/img/4-golden-crown-casino.png";
import image5 from "assets/img/5-loki-casino.png";
import image6 from "assets/img/6-dazard.png";
import image7 from "assets/img/7-kingbilly-casino.png";
import image8 from "assets/img/8-hellspin.png";
import image9 from "assets/img/9-casinoNic.png";

// Games Top
import gamesTop1 from "assets/img/games/top/TOP_Asgard.png";
import gamesTop2 from "assets/img/games/top/Top_BigBassBonanza.png";
import gamesTop3 from "assets/img/games/top/Top_Bonanza.png";
import gamesTop4 from "assets/img/games/top/Top_Book of Dead.png";
import gamesTop5 from "assets/img/games/top/TOP_Buffalo.png";
import gamesTop6 from "assets/img/games/top/TOP_DeadOrAlive.png";
import gamesTop7 from "assets/img/games/top/TOP_FireJoker.png";
import gamesTop8 from "assets/img/games/top/Top_Gonzo_s Quest.png";
import gamesTop9 from "assets/img/games/top/TOP_Legacy Of Dead.png";
import gamesTop10 from "assets/img/games/top/TOP_Legacy.png";
import gamesTop11 from "assets/img/games/top/TOP_Midas.png";
import gamesTop12 from "assets/img/games/top/Top_PrimateKing.png";
import gamesTop13 from "assets/img/games/top/TOP_Starburst.png";
import gamesTop14 from "assets/img/games/top/Top_Sweet Bonanza.png";
import gamesTop15 from "assets/img/games/top/Top_Wolf Gold.png";

// Games Max Payout
import gamesMax1 from "assets/img/games/maxPayout/Max_Tombstone RIP.png";
import gamesMax2 from "assets/img/games/maxPayout/Max_Casino Zeppelin.png";
import gamesMax3 from "assets/img/games/maxPayout/MAX_StarBXtreme.png";
import gamesMax4 from "assets/img/games/maxPayout/Max_Super Boost.png";
import gamesMax5 from "assets/img/games/maxPayout/MAX_Buffalo Blitz.png";
import gamesMax6 from "assets/img/games/maxPayout/Max_Aloha.png";
import gamesMax7 from "assets/img/games/maxPayout/MAX_San Quentin.png";
import gamesMax8 from "assets/img/games/maxPayout/MAX_Rome.png";
import gamesMax9 from "assets/img/games/maxPayout/MAX_MAX.png";
import gamesMax10 from "assets/img/games/maxPayout/MAX_Robin.png";
import gamesMax11 from "assets/img/games/maxPayout/MAX_Appolo Pays.png";
import gamesMax12 from "assets/img/games/maxPayout/MAX_DeadOrAlive.png";
import gamesMax13 from "assets/img/games/maxPayout/MAX_Flower.png";
import gamesMax14 from "assets/img/games/maxPayout/MAX_PIRATE.png";
import gamesMax15 from "assets/img/games/maxPayout/MAX_Buffalo King.png";

// Games Highest RTP
import gamesRtp1 from "assets/img/games/highestRtp/RTP_FruityBeats.png";
import gamesRtp2 from "assets/img/games/highestRtp/RTP_Joker.png";
import gamesRtp3 from "assets/img/games/highestRtp/RTP_BookOf99.png";
import gamesRtp4 from "assets/img/games/highestRtp/RTP_Joker6000.png";
import gamesRtp5 from "assets/img/games/highestRtp/RTP_1429.png";
import gamesRtp6 from "assets/img/games/highestRtp/RTP_Cat.png";
import gamesRtp7 from "assets/img/games/highestRtp/RTP_BloodSuckers.png";
import gamesRtp8 from "assets/img/games/highestRtp/RTP_Jokerizer.png";
import gamesRtp9 from "assets/img/games/highestRtp/RPT_BookOfSun.png";
import gamesRtp10 from "assets/img/games/highestRtp/RTP_JackHammer2.png";
import gamesRtp11 from "assets/img/games/highestRtp/RTP_Hotline.png";
import gamesRtp12 from "assets/img/games/highestRtp/RTP_Pumpkin.png";
import gamesRtp13 from "assets/img/games/highestRtp/RTP_Medusa.png";
import gamesRtp14 from "assets/img/games/highestRtp/RTP_MagicOak.png";
import gamesRtp15 from "assets/img/games/highestRtp/RTP_Mount.png";

export const cards = [
  {
    id: 1,
    heading: "UP TO $8,000",
    text: "& 700 FREE SPINS",
    promo: "OUR TOP OFFER",
    button: "CLAIM",
    info: "Min Deposit: 10. Wagering requirement x50. Withdrawal limits $6000 Free Spins on All Lucky Clovers 5 Slot. Find the Full T&C’s on the site!",
    redirect: "https://cutt.ly/OnSite_Casino_Dundee",
    url: image1,
  },
  {
    id: 2,
    heading: "UP TO $10,000",
    text: "& UP TO 100 FREE SPINS",
    promo: "DAILY 20% CASHBACK",
    button: "CLAIM",
    info: "Min Deposit: 30. Wagering requirement x40. Withdrawal limits $7500 p/d, $15,000 p/w, $45,000 p/m. Find the Full T&C’s on the site!",
    redirect: "https://cutt.ly/OnSite_Casino_NeoSpin",
    url: image2,
  },
  {
    id: 3,
    heading: "UP TO $7500",
    text: "& 550 FREE SPINS",
    promo: "VIP BONUS",
    button: "CLAIM",
    info: "Min Deposit: 20. Wagering requirement x50. Withdrawal limits Depends on payment method. Payout speed Instant - 3 Days. Find the Full T&C’s on the site!",
    redirect: "https://cutt.ly/OnSite_Casino_RickyCasino",
    url: image3,
  },
  {
    id: 4,
    heading: "UP TO $10,000",
    text: "PLUS 10 BONUS",
    promo: "& 100 FREE SPINS",
    button: "CLAIM",
    info: "Min Deposit: 30. Wagering requirement x40. Withdrawal limits Depends on method. Payout speed Instant - 3 Days. Find the Full T&C’s on the site!",
    redirect: "https://cutt.ly/OnSite_Casino_GoldenCrown",
    url: image4,
  },
  {
    id: 5,
    heading: "UP TO $10,000",
    text: "& 100 FREE SPINS",
    promo: "WEEKLY RELOADS",
    button: "CLAIM",
    info: "Min Deposit 20. Wagering requirements x40. Withdrawals limit 15,000 AUD weekly. Find the Full T&C’s on the site!",
    redirect: "https://cutt.ly/OnSite_Casino_LokiCasino",
    url: image5,
  },
  {
    id: 6,
    heading: "100% UP TO $7,500",
    text: "& 100 FREE SPINS",
    promo: "CASINO LOTTERY",
    button: "CLAIM",
    info: "Min Deposit: 20 . Wagering requirement x40. Withdrawal limits Depends on method. Find the Full T&C’s on the site!",
    redirect: "https://cutt.ly/OnSite_Casino_Dazard",
    url: image6,
  },
  {
    id: 7,
    heading: "UP TO $2,500",
    text: "& 250 FREE SPINS",
    promo: "TONS OF PROMOTIONS!",
    button: "CLAIM",
    info: "Min Deposit 10. Wager: 49x. Max win: €/$50. Maximum bet: €/$10. 100 FS – 20 FS x 5. Find the Full T&C’s on the site!",
    redirect: "https://cutt.ly/OnSite_Casino_KingBilly",
    url: image7,
  },
  {
    id: 8,
    heading: "UP TO $1,200",
    text: "& 150 FREE SPINS",
    promo: "CASH RELOADS",
    button: "CLAIM",
    info: "Min Deposit: 15. Wagering requirement x40. Withdrawal limits $15,000. Payout speed Instant - 5 Days. Find the Full T&C’s on the site!",
    redirect: "https://cutt.ly/OnSite_Casino_HellSpins",
    url: image8,
  },
  {
    id: 9,
    heading: "UP TO $5,000",
    text: "& 200 FREE SPINS",
    promo: "DAILY BONUSES",
    button: "CLAIM",
    info: "Min Deposit: 20. Wagering requirement x50. Withdrawal limits 7,500 AUD a week. Payout speed Instant - 1-5 Days. Find the Full T&C’s on the site!",
    redirect: "https://cutt.ly/OnSite_Casino_CasinoNic",
    url: image9,
  },
];

export const gamesTop = [
  {
    id: 1,
    heading: "Age of Asgard",
    text: "Yggdrasil",
    redirect: "https://cutt.ly/x3fq5iA",
    url: gamesTop1,
  },
  {
    id: 2,
    heading: "Big Bass Bonanza",
    text: "Pragmatic Play",
    redirect: "https://cutt.ly/x3fq5iA",
    url: gamesTop2,
  },
  {
    id: 3,
    heading: "Bonanza",
    text: "Big Time Gaming",
    redirect: "https://cutt.ly/33fwvMf",
    url: gamesTop3,
  },
  {
    id: 4,
    heading: "Book of Dead",
    text: "Play N’GO",
    redirect: "https://cutt.ly/x3fq5iA",
    url: gamesTop4,
  },
  {
    id: 5,
    heading: "Dead or Alive",
    text: "NetEnt",
    redirect: "https://cutt.ly/93fwJgi",
    url: gamesTop5,
  },
  {
    id: 6,
    heading: "Fire Joker",
    text: "Play N’GO",
    redirect: "https://cutt.ly/93fwJgi",
    url: gamesTop6,
  },
  {
    id: 7,
    heading: "Gonzo’s Quest",
    text: "NetEnt",
    redirect: "https://cutt.ly/93fwJgi",
    url: gamesTop7,
  },
  {
    id: 8,
    heading: "Legacy of Dead",
    text: "Play N’GO",
    redirect: "https://cutt.ly/q3fwRrC",
    url: gamesTop8,
  },
  {
    id: 9,
    heading: "Legacy of the Tiger",
    text: "Playtech",
    redirect: "https://cutt.ly/33fwvMf",
    url: gamesTop9,
  },
  {
    id: 10,
    heading: "Midas",
    text: "Thunderkick",
    redirect: "https://cutt.ly/33fwvMf",
    url: gamesTop10,
  },
  {
    id: 11,
    heading: "Primate King",
    text: "Red Tiger Gaming",
    redirect: "https://cutt.ly/q3fwRrC",
    url: gamesTop11,
  },
  {
    id: 12,
    heading: "Big Wild Buffalo",
    text: "Belatra Games",
    redirect: "https://cutt.ly/q3fwRrC",
    url: gamesTop12,
  },
  {
    id: 13,
    heading: "Starburst",
    text: "NetEnt",
    redirect: "https://cutt.ly/x3fq5iA",
    url: gamesTop13,
  },
  {
    id: 14,
    heading: "Sweet Bonanza",
    text: "",
    redirect: "https://cutt.ly/x3fq5iA",
    url: gamesTop14,
  },
  {
    id: 15,
    heading: "Wolf Gold",
    text: "",
    redirect: "https://cutt.ly/x3fq5iA",
    url: gamesTop15,
  },
];

export const gamesMax = [
  {
    id: 1,
    heading: "Tombstone RIP",
    text: "Nolimit City",
    redirect: "https://cutt.ly/x3fq5iA",
    url: gamesMax1,
  },
  {
    id: 2,
    heading: "Cazino Zeppelin Reloaded",
    text: "Yggdrasil",
    redirect: "https://cutt.ly/x3fq5iA",
    url: gamesMax2,
  },
  {
    id: 3,
    heading: "Starburst XXXTreme",
    text: "NetEnt",
    redirect: "https://cutt.ly/x3fq5iA",
    url: gamesMax3,
  },
  {
    id: 4,
    heading: "Super boost",
    text: "Relax Gaming",
    redirect: "https://cutt.ly/q3fwRrC",
    url: gamesMax4,
  },
  {
    id: 5,
    heading: "Buffalo Blitz",
    text: "Playtech",
    redirect: "https://cutt.ly/93fwJgi",
    url: gamesMax5,
  },
  {
    id: 6,
    heading: "Aloha Spirit Xtralock",
    text: "Swintt",
    redirect: "https://cutt.ly/93fwJgi",
    url: gamesMax6,
  },
  {
    id: 7,
    heading: "San Quentin",
    text: "Nolimit City",
    redirect: "https://cutt.ly/93fwJgi",
    url: gamesMax7,
  },
  {
    id: 8,
    heading: "Rome: The Golden Age",
    text: "NetEnt",
    redirect: "https://cutt.ly/x3fq5iA",
    url: gamesMax8,
  },
  {
    id: 9,
    heading: "Max Megaways",
    text: "Big Time Gaming",
    redirect: "https://cutt.ly/93fwJgi",
    url: gamesMax9,
  },
  {
    id: 10,
    heading: "Robin Nottingham Raiders",
    text: "Peter and Sons",
    redirect: "https://cutt.ly/x3fq5iA",
    url: gamesMax10,
  },
  {
    id: 11,
    heading: "Apollo Pays Megaways",
    text: "Big Time Gaming",
    redirect: "https://cutt.ly/33fwvMf",
    url: gamesMax11,
  },
  {
    id: 12,
    heading: "Dead or Alive",
    text: "NetEnt",
    redirect: "https://cutt.ly/x3fq5iA",
    url: gamesMax12,
  },
  {
    id: 13,
    heading: "Wild Flower",
    text: "Big Time Gaming",
    redirect: "https://cutt.ly/33fwvMf",
    url: gamesMax13,
  },
  {
    id: 14,
    heading: "Pirate Pays Megaways",
    text: "Big Time Gaming",
    redirect: "https://cutt.ly/93fwJgi",
    url: gamesMax14,
  },
  {
    id: 15,
    heading: "Buffalo King",
    text: "Pragmatic Play",
    redirect: "https://cutt.ly/x3fq5iA",
    url: gamesMax15,
  },
];

export const gamesRtp = [
  {
    id: 1,
    heading: "Fruity Beats Xtreme",
    text: "Spinmatic",
    redirect: "https://cutt.ly/V3mXpoq",
    url: gamesRtp1,
  },
  {
    id: 2,
    heading: "Mega Joker",
    text: "NetEnt",
    redirect: "https://cutt.ly/93fwJgi",
    url: gamesRtp2,
  },
  {
    id: 3,
    heading: "Book of 99",
    text: "Relax Gaming",
    redirect: "https://cutt.ly/x3fq5iA",
    url: gamesRtp3,
  },
  {
    id: 4,
    heading: "Jackpot 6000",
    text: "NetEnt",
    redirect: "https://cutt.ly/x3fq5iA",
    url: gamesRtp4,
  },
  {
    id: 5,
    heading: "1429 Uncharted Seas",
    text: "Thunderkick",
    redirect: "https://cutt.ly/x3fq5iA",
    url: gamesRtp5,
  },
  {
    id: 6,
    heading: "The Catfather",
    text: "Pragmatic Play",
    redirect: "https://cutt.ly/V3mXpoq",
    url: gamesRtp6,
  },
  {
    id: 7,
    heading: "Blood Suckers",
    text: "NetEnt",
    redirect: "https://cutt.ly/93fwJgi",
    url: gamesRtp7,
  },
  {
    id: 8,
    heading: "Jokerizer",
    text: "Yggdrasil",
    redirect: "https://cutt.ly/93fwJgi",
    url: gamesRtp8,
  },
  {
    id: 9,
    heading: "Book of Sun: Choice",
    text: "Booongo",
    redirect: "https://cutt.ly/q3fwRrC",
    url: gamesRtp9,
  },
  {
    id: 10,
    heading: "Jack Hammer 2",
    text: "NetEnt",
    redirect: "https://cutt.ly/93fwJgi",
    url: gamesRtp10,
  },
  {
    id: 11,
    heading: "Hotline",
    text: "NetEnt",
    redirect: "https://cutt.ly/93fwJgi",
    url: gamesRtp11,
  },
  {
    id: 12,
    heading: "Pumpkin Patch",
    text: "Habanero",
    redirect: "https://cutt.ly/x3fq5iA",
    url: gamesRtp12,
  },
  {
    id: 13,
    heading: "Mighty Medusa",
    text: "Habanero",
    redirect: "https://cutt.ly/x3fq5iA",
    url: gamesRtp13,
  },
  {
    id: 14,
    heading: "Magic Oak",
    text: "Habanero",
    redirect: "https://cutt.ly/x3fq5iA",
    url: gamesRtp14,
  },
  {
    id: 15,
    heading: "Mount Mazuma",
    text: "Habanero",
    redirect: "https://cutt.ly/x3fq5iA",
    url: gamesRtp15,
  },
];