import React, { useState, useEffect } from "react";

// components
import Navbar from "components/Navbars/AuthNavbar";
import Footer from "components/Footers/Footer";
import { cards, gamesTop, gamesMax, gamesRtp } from "../data";
import bannerDesktop from "assets/img/cover.png";
import bannerMobile from "assets/img/banner-mobile.png";
import gameGif from "assets/img/ezgif.com-optimize.gif";

const CardItem = ({ heading, text, promo, button, info, redirect, url }) => {
  const redirectToLink = () => {
    window.open(redirect, "_blank");
  };

  return (
    <div className="w-full lg:w-4/12 px-4 mr-auto ml-auto">
      <div className="relative flex flex-col min-w-0 mb-1 break-words bg-white w-full shadow-lg rounded-lg">
        <img alt="..." src={url} className="w-full align-middle rounded-t-lg" />
        <blockquote
          className="relative p-4 mb-2"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <svg
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 583 95"
            className="absolute left-0 w-full block h-95-px -top-94-px"
          ></svg>
          <div
            style={{
              background: "yellow",
              border: "1px dashed black",
              paddingInline: "15px",
              paddingBlock: "5px",
              position: "relative",
              marginTop: "-35px",
            }}
          >
            <h4 className="text-xl font-bold text-black">{promo}</h4>
          </div>
          <h4
            className="font-bold text-black mt-6"
            style={{ fontSize: "2.3rem" }}
          >
            {heading}
          </h4>
          <p
            className="font-light text-black"
            style={{ fontSize: "1.6rem", fontWeight: "bold" }}
          >
            {text}
          </p>
          <button
            className="button mt-6"
            style={{ alignSelf: "center" }}
            onClick={redirectToLink}
          >
            {button}
          </button>
          <div className="flex items-center mt-2">
            <svg
              aria-hidden="true"
              className="w-8 h-8 text-yellow-400"
              fill="#e3a008"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>First star</title>
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
            </svg>
            <svg
              aria-hidden="true"
              className="w-8 h-8 text-yellow-400"
              fill="#e3a008"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Second star</title>
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
            </svg>
            <svg
              aria-hidden="true"
              className="w-8 h-8 text-yellow-400"
              fill="#e3a008"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Third star</title>
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
            </svg>
            <svg
              aria-hidden="true"
              className="w-8 h-8 text-yellow-400"
              fill="#e3a008"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Fourth star</title>
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
            </svg>
            <svg
              aria-hidden="true"
              className="w-8 h-8 text-gray-300 dark:text-gray-500"
              fill="#e3a008"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Fifth star</title>
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
            </svg>
          </div>
        </blockquote>
      </div>
      <p className="info-text mb-6">{info}</p>
    </div>
  );
};

const GameItem = ({ heading, text, url, redirect }) => {
  const redirectToLink = () => {
    window.open(redirect, "_blank");
  };

  return (
    <div className="w-fifth px-1 mr-auto ml-auto game-item">
      <figure className="snip1577">
        <img src={url} alt="game slots" />
        <figcaption>
          <button className="game-button" onClick={redirectToLink}>
            {heading}
          </button>
        </figcaption>
      </figure>
      <p className="font-bold px-2">{heading}</p>
      <p className="game-text px-2">{text}</p>
    </div>
  );
};

export default function Landing() {
  const [gamesActiveTab, setGamesActiveTab] = useState(0);
  const [width, setWindowWidth] = useState(0);
  useEffect(() => {
    updateDimensions();

    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };

  const responsive = {
    mobile: width < 500,
  };

  return (
    <>
      <Navbar transparent />
      <main>
        <div className="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75">
          <div className="absolute top-0 w-full h-full bg-center bg-cover">
            <img
              className="absolute top-0 w-full h-full bg-center bg-cover"
              src={responsive.mobile ? bannerMobile : bannerDesktop}
              alt="banner"
            />
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-blueGray-200 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </div>

        <section
          className={`pb-20 bg-blueGray-200 ${
            responsive.mobile ? "mt-5" : "-mt-24"
          }`}
        >
          <div className="container mx-auto px-4 w-full">
            <div className="flex flex-wrap">
              {cards?.map((item) => (
                <CardItem
                  heading={item.heading}
                  text={item.text}
                  promo={item.promo}
                  button={item.button}
                  url={item.url}
                  key={item.id}
                  info={item.info}
                  redirect={item.redirect}
                />
              ))}
            </div>

            <div
              className="flex w-full mb-4 mt-8"
              style={{
                flex: 1,
                alignItems: "flex-end",
                justifyContent: "space-between",
              }}
            >
              <h3 className="px-4 text-xl mb-2 font-semibold leading-normal">
                Review The Latest Slots
              </h3>

              <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 dark:text-gray-400">
                <li className="mr-2">
                  <div
                    onClick={() => setGamesActiveTab(0)}
                    className={`game-tab inline-block px-4 py-3 bg-blue-600 rounded-lg cursor-pointer ${
                      gamesActiveTab === 0 && "active"
                    }`}
                    aria-current="page"
                  >
                    Top Games
                  </div>
                </li>
                <li className="mr-2">
                  <div
                    onClick={() => setGamesActiveTab(1)}
                    className={`game-tab inline-block px-4 py-3 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white cursor-pointer ${
                      gamesActiveTab === 1 && "active"
                    }`}
                  >
                    Max Payout
                  </div>
                </li>
                <li className="mr-2">
                  <div
                    onClick={() => setGamesActiveTab(2)}
                    className={`game-tab inline-block px-4 py-3 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white cursor-pointer ${
                      gamesActiveTab === 2 && "active"
                    }`}
                  >
                    Highest RTP
                  </div>
                </li>
              </ul>
            </div>

            <div class="flex flex-wrap">
              {gamesActiveTab === 0 &&
                gamesTop?.map((item) => (
                  <GameItem
                    heading={item.heading}
                    text={item.text}
                    url={item.url}
                    key={item.id}
                    redirect={item.redirect}
                  />
                ))}
              {gamesActiveTab === 1 &&
                gamesMax?.map((item) => (
                  <GameItem
                    heading={item.heading}
                    text={item.text}
                    url={item.url}
                    key={item.id}
                    redirect={item.redirect}
                  />
                ))}
              {gamesActiveTab === 2 &&
                gamesRtp?.map((item) => (
                  <GameItem
                    heading={item.heading}
                    text={item.text}
                    url={item.url}
                    key={item.id}
                    redirect={item.redirect}
                  />
                ))}
            </div>

            {/* <div className="flex flex-wrap mt-32">
              <div className="px-4 mr-auto ml-auto yt-video">
                <h3 className="text-3xl mb-2 font-semibold leading-normal">
                  We Are YOUR SpinMates, and we are always on YOUR SIDE!
                </h3>
                <iframe
                  style={{ aspectRatio: "16 / 9" }}
                  width="100%"
                  src="https://www.youtube.com/embed/grTALrei5EE"
                  title="SpinMates video"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div> */}
          </div>
        </section>

        <section className="relative py-20">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                <img
                  alt="Game gif"
                  className="max-w-full rounded-lg shadow-lg mb-4"
                  src={gameGif}
                  // src="https://images.unsplash.com/photo-1555212697-194d092e3b8f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80"
                />
              </div>
              <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <h3 className="text-3xl font-semibold">
                    WE GOT OVER 10,000 FREE SPINS!
                  </h3>
                  <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                    On the top casinos on our site, we got more than 10,000 FREE
                    SPINS on the games chosen by the casinos. When you make a
                    deposit, the free spins are part of the welcome package,
                    together with the cash bonus. After the welcome bonus
                    package, you are eligible for the other promotions that the
                    casinos have, and the best place to learn them is here! Hit
                    the button below and get your first bonus.{" "}
                  </p>
                  <button className="button mt-6" onClick={() => {}}>
                    GET BONUS
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pb-20 relative block bg-blueGray-800">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-blueGray-800 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4 lg:pt-24 lg:pb-64">
            <div className="flex flex-wrap text-center justify-center">
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold text-white">
                  GAMBLE RESPONSIBLY!
                </h2>
                <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-400">
                  Always try to have fun while gambling. If you are under the
                  influence of substances or emotions, please take a break.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="relative block py-24 lg:pt-0 bg-blueGray-800">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-center lg:-mt-64 -mt-48">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200">
                  <div className="flex-auto p-5 lg:p-10">
                    <h4 className="text-2xl font-semibold">
                      Subscribe to our newsletter and talk Pokie with us. We
                      share the latest bonuses and all there is to know about
                      slots!
                    </h4>
                    <div className="relative w-full mb-3 mt-8">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="full-name"
                      >
                        Name (Optional)
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Full Name"
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="email"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Email"
                      />
                    </div>
                    <div className="text-center mt-6">
                      <button
                        className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                      >
                        Subscribe
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="relative py-20">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap">
              <div className="w-full ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <h3 className="text-3xl font-semibold">Who we are</h3>
                  <div className="px-4 mr-auto ml-auto yt-video mt-4 mb-4">
                      <iframe
                        style={{ aspectRatio: "16 / 9" }}
                        width="100%"
                        src="https://www.youtube.com/embed/grTALrei5EE"
                        title="SpinMates video"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                    We are your Spin Mates. We review casinos in Australia and
                    we love it! Even though there are tons of unsafe sites,
                    there are plenty of trusted casinos where you can enjoy your
                    pokie spins and never need to worry about your personal
                    information being shared, or frozen money you can’t take. We
                    made it our job to present you with the best ones. We do
                    video reviews and they can be found on our YouTube Channel.
                    You can also read the spoken text, find the casinos we
                    mention in the video just one click away, and see our other
                    social media channels in the description. There are tons of
                    Australian casinos, and we love spinning pokies and
                    reviewing the sites. Watch us go through the casino website,
                    go through the steps necessary for you to play, check their
                    welcome offers, restrictions and payment methods. We also
                    find relevant reviews on third-party sites about the casino,
                    and what the Aussie community thinks about it. On top of
                    that, we give the latest and most popular slot games a spin
                    and give a review of them as well. We talk about the game
                    mechanics, their design, the symbols and RTP, and their
                    bonus features. We will also point you to the best casinos
                    for each particular game, which will definitely save you
                    time. One thing is certain - We all absolutely hate illegal
                    and scammy casinos, and having previously lost a lot of
                    money to scams, we swore to do something about it. You can
                    find a Casino Black List in our Facebook Group - Here we
                    post the blacklisted casinos, with comments on their
                    actions, and any tips on how to prevent them. You are
                    welcome to share your experience in the group, whether it’s
                    positive or negative.
                  </p>
                  <h3 className="text-3xl font-semibold mt-4">What we offer</h3>
                  <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                    As you can see, there aren’t a ton of casino sites and
                    offers on our page. That’s because we carefully select what
                    we show here. We ultimately want to offer you the best
                    possible experience while you spin pokies. This includes:
                    you being safe, having a fair dinkum choice of slot games,
                    deposit and withdraw money whenever you like, and play
                    without any obstacles. You can find our top nine casinos on
                    the site, with their current welcome bonuses, and their
                    terms and conditions. If you like what you see, and want to
                    get the welcome bonus, there is a button next to it. If you
                    need more information about the casinos, check our YouTube
                    Channel HERE! Apart from that, you will see the most popular
                    and latest slot games found in Australia, and their matching
                    casinos which will get you spins and bonuses on that game.
                    Even though we do our best, we can’t possibly cover all the
                    games, all the casinos and every bad news happening on the
                    internet. Each and every one of us gets bombarded with
                    information, but might miss the very obvious. In our
                    Facebook Group called Australian Pokie Talks, we do justice
                    to the name and talk about pokies - We share big wins, share
                    new slots, and oldie goldies. We also comment on new
                    casinos, what they do good and what they do bad. The most
                    terrible ones are going to find themselves on the Blacklist!
                    Apart from that, our YouTube channel is slowly filled with
                    the Australian casinos that are on our site, and the ones
                    that we vouch for. We review them, and make your job of
                    choosing them easier. You can find a link in the youtube
                    description of the review to the casinos that aren’t on the
                    site. You can also find slot gameplays and spins there, with
                    the most lucrative casinos for that game. We sometimes
                    answer questions and explain pokie things on the channel as
                    well, so make sure you hit the subscribe button 🙂 Last but
                    not least is our newsletter, where we send weekly slots
                    information with news slots, new casino deals, reviews we
                    did and overall pokie talks. We tend not to spam, but we
                    will hit you with a mid-week deal if we manage to get
                    something new for you.
                  </p>
                  <h3 className="text-3xl font-semibold mt-4">
                    How we choose our casinos
                  </h3>
                  <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                    Australians love to play pokies - it’s definitely one of
                    their favorite hobbies. There is just something in the
                    anticipation of the spinning reel. With so many people
                    enjoying the one-armed bandits, it’s no surprise there are
                    tons and tons of new games, bonuses and online casinos, and
                    with that - problems. A lot of the casinos that are served
                    on Australians are offshore casinos, illegal casinos and
                    no-licensed casinos. Those casinos can do whatever they
                    want: they can delete your account after a big win, they can
                    restrict you from taking your win money, use your personal
                    information and promote your products that you haven’t
                    subscribed to, and many other BAD things. Spin Mates will
                    not have it! Among the top priorities that we have is the
                    way we handle our new deals. We love to enjoy while playing
                    and we love sharing it with you - What else would you expect
                    from your spin mates. We visit their sites, test the links,
                    claim the bonuses and see if anything fishy is happening. We
                    check the payment methods and read reviews that the fellow
                    spin mates left on their site and all over the internet. We
                    only serve you the best! It goes both ways. That way you are
                    happy, you come back and enjoy what we offer, and we are
                    happy.
                  </p>
                  <h3 className="text-3xl font-semibold mt-4">
                    Top Rated Slots in Australia
                  </h3>
                  <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                    For a slot game to enjoy big popularity, it needs to be an
                    all rounder - to have a sharp design and inviting sound, to
                    have modern game mechanics and to offer exciting bonus
                    features and nice wins. The following slots are here for a
                    reason, and we choose them by the following criteria:
                    High-quality and unique design Electric bonus features High
                    RTP and high payouts Interesting Theme These are our top
                    filters, and they help us deliver the most exciting slots.
                    Find our top slot games below:
                  </p>
                  <h3 className="text-3xl font-semibold mt-4">
                    Gonzo’s Quest slot
                  </h3>
                  <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                    Dive into Gonzo’s Adventure in the search of the lost city
                    of El Dorado. Visit the bays of Peru and maybe luck will be
                    on your side! This Net Ent online slot is based on the
                    historical character ​​Gonzalo Pizzaro, and has been in the
                    top three slots for several years now - we are not talking
                    only in Australia, but worldwide! Gonzo is a rock star in
                    the slots world and everybody wants an autograph! The game
                    has 5 reels and 20 paylines, and works with cascading reels.
                    You can get up to 15 free spins in the bonus round, where
                    you have the most chances to land a win and get closer to
                    the city of gold. Gonzo is staying tall with 96% RTP and
                    definitely finds its place in the top Australian slot games.
                    You can play gonzo’s quest at Dundee casino, where you can
                    deposit and get $8000 games bonus as well as 700 Free Spins
                    over the course of four deposits!. Just hit the button, fill
                    in the form, and grab the bonus. Dundee is in the top 3
                    casinos in Australia in 2022, so you can be sure you are in
                    good hands.
                  </p>
                  <h3 className="text-3xl font-semibold mt-4">
                    Book of Dead slot
                  </h3>
                  <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                    The slot is about Ancient Egypt where you will be taken
                    inside some of the pharaoh chambers and try to find their
                    treasure. With the help of the Book of Dead and the symbols
                    in it, you have the chance to win up to x5,000 your bet. The
                    game has only one bonus feature, which is a small bummer for
                    me, but the free spins feature builds up, and if you hit 3
                    or 4 symbols in a single feature, you are up for a solid
                    grab. I have had my top wins on Book of Dead, and it’s
                    expected with an RTP of 96,21%. The place to play Book of
                    Dead slot is definitely King Billy Casino where you Get up
                    to 2500$ AUS + 250 Free spins. Their website makes you feel
                    like royalty and if you are a fan of Medieval times, you
                    will find yourself on a lovely casino site.
                  </p>
                  <h3 className="text-3xl font-semibold mt-4">
                    Starburst slot
                  </h3>
                  <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                    Australians have ranked this game high for some years now.
                    It may be because of the space theme, or the shiny gems, but
                    we think it’s about the massive rewards that it has paid out
                    over the years. Starburst slot can be found in Australian
                    casinos and it does bring positive ratings all over. The
                    space theme is a weak spot for many players, and somehow
                    starburst stood out from the crowd and grabbed 1st place.
                    The Net Ent slot has five reels and 96,1% RTP. The game has
                    a retro vibe, with the neon background and 80s music, which
                    may be the reason why a lot of people love it.
                    Unfortunately, the game doesn’t have a bonus feature, which
                    is a huge deal breaker if you ask me, but that doesn’t seem
                    to matter and the players love it. There are 20 paylines and
                    the most you can win in a single spin is $50,000. Some of
                    the places where you can find Starburst Slot is Ricky Casino
                    and they offer $7500 games bonus as well as 550 Free Spins
                    on selected games (Starburst might be in it!). Click the
                    button on the top, fill in the registration form with your
                    information, and you are eligible for the bonus! Remember -
                    we only lead you to the most trusted and best casinos out
                    there.
                  </p>
                  <h3 className="text-3xl font-semibold mt-4">
                    Mega Moolah slot
                  </h3>
                  <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                    Mega Moolah slot must be in the top three slots of all time
                    for online players, and there is only one reason for it -
                    the game paid out over some of the biggest wins in history.
                    There is a lot more to Mega Moolah Slot than the outdated
                    2006 look, which btw, was a hit when Microgaming released it
                    - now, a few years shy of two decades later, people love it
                    more than ever before! It comes with five reels and 25
                    paylines, and a fancy RTP of something above 96%. This
                    Safari themed slot was among the first slots to adopt an
                    African theme, and that might be the key to its success. You
                    can play Mega Moolah on Dundee where you can get Up To $8000
                    Welcome Bonus over foud deposits. This is the best casino in
                    the Australian area, and you should definitely check it out!
                  </p>
                  <h3 className="text-3xl font-semibold mt-4">
                    Types of Bonuses
                  </h3>
                  <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                    There are many different types of bonuses that the casinos
                    are using to attract new players and existing ones. These
                    bonuses help the players get familiar with the casino, check
                    out the games and give them an initial boost to start
                    playing. Almost all of the bonuses can be claimed once, and
                    they are divided into several categories. Free Spins One of
                    the most common promotions that the casinos give to new and
                    existing players are free spins. Don’t get confused with the
                    free spins in the bonus features in online games - those are
                    different free spins. The free spins that you get from the
                    casinos are for the base game. It’s for you to try the game,
                    check out the design and with those free spins, you can win
                    the free bonus features of the game. Everything that you win
                    in the free spins will be transferred to your account, but
                    it depends on the casino wagering requirement if you can
                    take the free spins winnings, or you have to spin a bit more
                    to meet their terms. No Deposit The classic no deposit bonus
                    is also a must have in almost all of the casinos online.
                    Each player gets a certain amount that the casino gives in
                    form of cash that can be played on the casino games, but
                    without a demand of the player to make a deposit. This is
                    also one of the perfect ways to try a new game, try the
                    casino and see if this is the right fit for you. In many of
                    the casinos on our site, you can see something like a $20 No
                    Deposit Bonus on certain games that the casino has. Many
                    times, these will be the new games, promotion of old ones or
                    they might be on games with themes that are currently
                    popular like Christmas or Saint Patricks’. Same as the Free
                    Spins bonuses, the no deposit bonuses have real winnings and
                    you play with real money. You can withdraw the cash once you
                    meet the wagering requirements of the casino. Welcome
                    bonuses A welcome bonus, as the name states, is for the new
                    players and the ones who are making an account at a casino
                    for the very first time. They are eligible for a welcome
                    bonus, which can be in the form of Free Spins, Seconds of
                    Spins, a bonus percentage of what you deposit. You need to
                    make a deposit to be able to claim the welcome bonus, and
                    it’s the only bonus that you can take when you begin playing
                    on a new site - It will open your door to the other bonuses
                    that the casino gives.
                  </p>
                  <h3 className="text-3xl font-semibold mt-4">
                    Free vs Real Money Pokies
                  </h3>
                  <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                    There aren’t that many differences between free and real
                    money pokies except the fact that one of them is played with
                    real money and the other one is a demo version of the slot.
                    This asks another key question that people ask - why would
                    you play a free pokie when you can play a paid one? Well,
                    the free pokies are great for new games, where you can learn
                    the slot without having the risk of losing money. Maybe the
                    bonus features are not the ones that you prefer and the game
                    mechanics work differently than the ones you play. Maybe the
                    theme and design won’t give you excitement while playing Or
                    maybe, it’s simply that you don’t have enough cash to spin
                    real slots. Whatever the case is, people love to play demo
                    slots, and we encourage you to try and spin a demo version
                    of a slot game before you play with real money. The notable
                    disadvantage from the demo version slots is that the wins
                    are fake and you won’t be able to take any of them in your
                    wallet, but also, anything you lose, won’t be taken out of
                    your wallet as well. On the other hand, the real money
                    pokies have an obvious advantage - all of the wins are in
                    your wallet, but you have to be careful as you are playing
                    with real money. Apart from that, there is no difference
                    with the paid and free pokies. The design is the same, the
                    win frequency is the same, the RTP of the slot as well as
                    the bonus features. Our recommendation is that when you want
                    to play a new slot, you should try a demo gameplay of the
                    game when spinning for the first time. If you like it, there
                    are always casinos that offer welcome bonuses and free spins
                    for that particular game - and that’s where we come into
                    play. You choose the game, and we will take you to the right
                    casino where you will get a lot more than what you find on
                    Google.
                  </p>
                  <h3 className="text-3xl font-semibold mt-4">
                    Gamble Responsibly
                  </h3>
                  <h4 className="text-2xl font-semibold mt-4">
                    It’s not a way to make money
                  </h4>
                  <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                    Gambling is a world problem. It’s not a road you can go down
                    and earn money. Gambling is the reason many people lose
                    fortunes of money and never recover from the financial
                    losses. It should be a sport only played with your extra
                    money and not a way to earn cash.
                  </p>
                  <h4 className="text-2xl font-semibold mt-4">
                    Money and Time Limits Are Important
                  </h4>
                  <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                    This is one of the most important tips that the governments
                    are suggesting to people fighting the gambling war. Setting
                    a limit will help you stop and not cross the line or go too
                    far. This can be done by setting a monthly or a daily
                    spending goal and making sure you never overstep that.
                  </p>
                  <h4 className="text-2xl font-semibold mt-4">
                    Never Gamble When You’re Emotional
                  </h4>
                  <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                    Emotions can cloud the judgment and decision making
                    abilities, so every gambling expert suggests that you avoid
                    gambling while under the influence of strong emotions. You
                    should only gamble when you feel relaxed and not stressed,
                    that’s how you can truly experience the fun that the slot
                    games offer.
                  </p>
                  <h4 className="text-2xl font-semibold mt-4">
                    Don’t Drink When Gambling
                  </h4>
                  <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                    Same as the emotions, alcohol can alter your thinking
                    abilities, put you in a dangerous spot and ease the hard
                    decisions that you would otherwise avoid. Alcohol clouds the
                    judgment and is never a good option when you want to gamble,
                    so avoid alcohol when visiting an online or a brick and
                    mortar casino.
                  </p>
                  <h4 className="text-2xl font-semibold mt-4">
                    Gamble What You Can Realistically Afford To Lose
                  </h4>
                  <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                    Before gambling, you should understand that when you leave
                    the chair, there is a possibility for you to have absolutely
                    nothing of the money you’ve wagered. Playing only with the
                    amount of cash that you can afford to lose will make the
                    overall gambling experience a lot more fun and stress-free.
                    This is closely connected with the very first tip we had
                    where you should set a limit of cash that you need to spend,
                    so make sure you use these tips.
                  </p>
                  <h4 className="text-2xl font-semibold mt-4">
                    If You Can’t Stop Contact the Casino
                  </h4>
                  <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                    The game and slots providers as well as the online casinos
                    will delete and cut access to your account if you email them
                    and tell them about your gambling problem. They can
                    furthermore direct you to credible organizations that can
                    help you with your gambling habit.
                  </p>
                  <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                    We are your Spin Mates, and we are always on your side.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
